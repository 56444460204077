import { graphql, useStaticQuery } from 'gatsby';
import { QuestionOptions, QuestionWithOptions } from 'forms/EmailUsForm/types';
import {
  CsQuestion,
  CsQuestionWithOptions,
  CsQuestionWithPlaceholder,
} from 'types/contentStack';
import {
  Question,
  QuestionWithOptionalPlaceholder,
  QuestionWithPlaceholder,
} from 'types/forms';
import { PageTitle, trackTooltipToggle } from './eventTracking';

type TooltipDefaults = {
  open: string;
  close: string;
};

type CsGlobalDefaults = {
  csPetGlobalConfig: {
    default_tooltip_labels: TooltipDefaults;
    select_field_default_placeholder: string;
  };
};

const globalConfigQuery = graphql`
  query {
    csPetGlobalConfig {
      default_tooltip_labels {
        open
        close
      }
      select_field_default_placeholder
    }
  }
`;

type QuestionProcessor = {
  processQuestion: (csQuestion: CsQuestion) => Question;
  processQuestionWithOptionalPlaceholder: (
    csQuestion: CsQuestionWithPlaceholder
  ) => QuestionWithOptionalPlaceholder;
  processQuestionWithPlaceholder: (
    csQuestion: CsQuestionWithPlaceholder
  ) => QuestionWithPlaceholder;
  processQuestionWithOptions: (csQuestion: CsQuestionWithOptions) => QuestionWithOptions;
};

const useQuestionProcessor = (pageTitle: PageTitle): QuestionProcessor => {
  const {
    select_field_default_placeholder: defaultPlaceholderText,
    default_tooltip_labels: defaultTooltipLabels,
  } = useStaticQuery<CsGlobalDefaults>(globalConfigQuery).csPetGlobalConfig;

  const processQuestion = (csQuestion: CsQuestion): Question => {
    const openTooltipText =
      csQuestion.main_details.tooltip.tooltip_open || defaultTooltipLabels.open;
    const closeTooltipText =
      csQuestion.main_details.tooltip.tooltip_close || defaultTooltipLabels.close;
    return {
      questionText: csQuestion.main_details.question_text,
      explanatoryText: csQuestion.main_details.explanatory_text,
      tooltip: csQuestion.main_details.tooltip.tooltip_text?.trim()
        ? {
            body: csQuestion.main_details.tooltip.tooltip_text.trim(),
            buttonText: {
              open: openTooltipText,
              close: closeTooltipText,
            },
            onExpand: () =>
              trackTooltipToggle(pageTitle, csQuestion.main_details.question_text),
            onCollapse: () =>
              trackTooltipToggle(pageTitle, csQuestion.main_details.question_text),
          }
        : undefined,
      alertText: csQuestion.main_details.alert_text,
    };
  };

  const processQuestionWithOptionalPlaceholder = (
    csQuestion: CsQuestionWithPlaceholder
  ): QuestionWithOptionalPlaceholder => ({
    ...processQuestion(csQuestion),
    placeholder: csQuestion.main_details.placeholder,
  });

  const processQuestionWithPlaceholder = (
    csQuestion: CsQuestionWithPlaceholder
  ): QuestionWithPlaceholder => ({
    ...processQuestion(csQuestion),
    placeholder: csQuestion.main_details.placeholder || defaultPlaceholderText,
  });

  const processOptions = (csQuestion: CsQuestionWithOptions): QuestionOptions[] =>
    csQuestion.answer_options.map((option) => ({
      name: option.name,
      value: option.value,
    }));

  // this function is only used to process questions without placeholders that have answer options
  const processQuestionWithOptions = (
    csQuestion: CsQuestionWithOptions
  ): QuestionWithOptions => ({
    ...processQuestion(csQuestion),
    options: processOptions(csQuestion),
  });

  return {
    processQuestion,
    processQuestionWithOptionalPlaceholder,
    processQuestionWithPlaceholder,
    processQuestionWithOptions,
  };
};

export default useQuestionProcessor;
