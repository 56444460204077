import {
  PrimaryCta,
  SecondaryCta,
} from '@rsa-digital/evo-shared-components/components/Cta';
import { graphql } from 'gatsby';
import React, { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { processMandatoryCta } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { ErrorAction, resetErrorStateAction } from 'state/error/actions';
import { CsCta } from 'types/contentStack';
import { StyledCtaDiv } from './styles';

enum CtaType {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

type CtaBlockProps = {
  cta: {
    cta: [CsCta];
    cta_type: CtaType;
    page_title: PageTitle;
  };
};

export const query = graphql`
  fragment CtaBlockError on cs__error_page_blocks {
    cta {
      cta {
        display_text
        screen_reader_text
        url
      }
      cta_type
    }
  }
`;

const CtaBlock: React.FC<CtaBlockProps> = ({ cta: { cta, cta_type, page_title } }) => {
  const dispatch = useDispatch<Dispatch<ErrorAction>>();
  const processedCta = processMandatoryCta(cta, page_title, () =>
    dispatch(resetErrorStateAction)
  );

  return (
    <StyledCtaDiv>
      {cta_type === CtaType.Primary && (
        <PrimaryCta cta={processedCta} data-cy="Primary CTA" />
      )}
      {cta_type === CtaType.Secondary && (
        <SecondaryCta cta={processedCta} data-cy="Secondary CTA" />
      )}
    </StyledCtaDiv>
  );
};

export default React.memo(CtaBlock);
