import { formatNumericDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { getCoverStartDate } from 'businessLogic/getCoverStartDate';
import { isPet8YearsOrOlderOnDate } from 'businessLogic/petAge';
import { trimEnd } from 'lodash';
import questions from 'forms/AboutYourPolicyForm/questions';
import { getDifferenceInDates } from 'helpers/dateHelpers';
import { formatInPounds } from 'helpers/poundsHelpers';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import { CsPlaceholders } from './replaceCsPlaceholders';

const combinePetNames = (
  quote: CurrentQuote,
  under8PetsOnly?: boolean,
  over8PetsOnly?: boolean
): string => {
  const allPetNames = quote.petInfos?.map((pet) => pet.petName) ?? [''];
  const petNamesUnder8 = quote.petInfos
    ?.filter(
      (pet) =>
        !isPet8YearsOrOlderOnDate(
          pet.dob,
          getCoverStartDate(quote.policyInfo?.coverStartDate)
        )
    )
    .map((pet) => pet.petName) ?? [''];

  const petNamesOver8 = quote.petInfos
    ?.filter((pet) =>
      isPet8YearsOrOlderOnDate(
        pet.dob,
        getCoverStartDate(quote.policyInfo?.coverStartDate)
      )
    )
    .map((pet) => pet.petName) ?? [''];

  // eslint-disable-next-line no-nested-ternary
  const petNames = under8PetsOnly
    ? petNamesUnder8
    : over8PetsOnly
    ? petNamesOver8
    : allPetNames;

  if (petNames.length === 1) {
    return trimEnd(petNames[0]);
  }
  return `${petNames.slice(0, petNames.length - 1).join(', ')} and ${
    petNames[petNames.length - 1]
  }`;
};

const getExpiryDate = (quote: CurrentQuote): string => {
  const switchOverDate = new Date(questions().switch_over_date);
  const quoteExpiry = new Date(quote.policyInfo?.quoteExpiryDate as string);
  if (!switchOverDate) {
    return quoteExpiry.toString();
  }
  const quoteStart = new Date(quote.policyInfo?.quoteStartDate as string);
  const daysBetween = getDifferenceInDates(quoteStart, switchOverDate);
  return daysBetween > 30 ? quoteExpiry.toString() : switchOverDate.toString();
};

export const quotePlaceholders: CsPlaceholders<CurrentQuote> = {
  petNames: {
    getSubstitutionText: (quote) => combinePetNames(quote),
    isPii: true,
  },
  petNamesUnder8: {
    getSubstitutionText: (quote) => combinePetNames(quote, true),
    isPii: true,
  },
  petNamesOver8: {
    getSubstitutionText: (quote) => combinePetNames(quote, undefined, true),
    isPii: true,
  },
  monthlyPaymentAmount: {
    getSubstitutionText: (quote) => formatInPounds(quote.price?.monthlyPrice.total),
    isPii: false,
  },
  monthlyTotalAmount: {
    getSubstitutionText: (quote) => {
      const monthlyAmount = quote.price?.monthlyPrice.total;
      return formatInPounds(monthlyAmount && monthlyAmount * 12);
    },
    isPii: false,
  },
  annualPaymentAmount: {
    getSubstitutionText: (quote) => formatInPounds(quote.price?.annualPrice.total),
    isPii: false,
  },
  customerEmail: {
    getSubstitutionText: (quote) => quote.customerInfo?.email ?? '',
    isPii: true,
  },
  customerFirstName: {
    getSubstitutionText: (quote) => quote.customerInfo?.firstName ?? '',
    isPii: true,
  },
  extraCoverLimit: {
    getSubstitutionText: (quote) => formatInPounds(quote.quoteOptions.extraCoverFeeLimit),
    isPii: false,
  },
  premierCoverLimit: {
    getSubstitutionText: (quote) =>
      formatInPounds(quote.quoteOptions.premierCoverFeeLimit),
    isPii: false,
  },
  quoteReference: {
    getSubstitutionText: (quote) => quote.policyInfo?.quoteNumber ?? '',
    isPii: false,
  },
  quoteExpiryDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.quoteExpiryDate
        ? formatNumericDate(new Date(getExpiryDate(quote)))
        : '',
    isPii: false,
  },
  coverStartDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.coverStartDate
        ? formatNumericDate(new Date(quote.policyInfo?.coverStartDate))
        : '',
    isPii: false,
  },
  perPetText: {
    getSubstitutionText: (quote) => ((quote.petInfos?.length || 0) > 1 ? ' per pet' : ''),
    isPii: false,
  },
};
