import { ButtonStyle } from '@rsa-digital/evo-shared-components/components/Button/styles';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledCtaDiv = styled.div`
  display: inline-block;
  margin-bottom: ${spacing(2)};
  width: 100%;

  ${mediaQuery.tabletPortrait`
    margin-right ${spacing(4)};
    margin-bottom: ${spacing(4)};
    width: auto;
  `}

  ${ButtonStyle}, &${ButtonStyle}  {
    width: 100%;
    &[data-variant="primary"] , &[data-variant="secondary"] {
      padding: ${spacing(2.5)} ${spacing(3)};
      width: 100%;
    }
  }
`;
