// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router';
import {
  addDaysToDate,
  addYearsToDate,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { DeeplinkRequest } from 'apiHelpers/quoteClient';
import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { getDeepLinkParams } from 'pages/pet/quote-generating';
import { RootState } from 'state/createStore';
import {
  AccidentAndIllnessCoverDuration,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import {
  UPDATE_TESCO_PAGE_LOAD_TRACKING_STATUS,
  UpdateTescoPageLoadTrackingStatusAction,
} from 'state/tescoEventTracking/actions';
import {
  ProductId,
  VoluntaryExcessAmount,
  VoluntaryExcessPercentage,
} from './businessConstants';
import { getCookie, setCookie } from './cookieHelpers';
import { getDateOnlyIsoString, isDateValid } from './dateHelpers';
import {
  getAggregatorFromProductId,
  getAggregatorFromReferrer,
  OptanonType,
} from './eventTracking';
import {
  ADOBE_ORG_ID,
  TescoPageLoadEvent,
  TescoPageViewEvent,
  TescoPageViewEventLevel,
  TescoPetExcesses,
  trackTescoEvent,
} from './eventTrackingForTesco';
import { getProductTier } from './pageTracking';
import { eventEmitter } from './pubSub';
import { quoteAndBuyRoutes } from './routingHelper';
import {
  retrieveData,
  retrieveDataWithExpiry,
  storeData,
  storeDataWithExpiry,
  TESCO_PAGE_LOAD_POSTCODE,
  TESCO_PAGE_LOAD_TRACKED_STORAGE_KEY,
  TESCO_PAGE_VIEW_IS_RETRIEVED_QUOTE,
  TESCO_PAGE_VIEW_OBJECT,
} from './sessionStorageHelpers';
import { CurrentQuote, useCurrentQuote } from './useCurrentQuote';

// EmptyObject is a type that can only have the value of {}
// We CANNOT define EmptyObject type directly like this:
// type EmptyObject = {};
// That would be a type that allows "any non-nullish value"
// See https://github.com/typescript-eslint/typescript-eslint/issues/2063#issuecomment-675156492
// and https://www.totaltypescript.com/the-empty-object-type-in-typescript
//
type EmptyObject = Record<string, never>;
type TescoPageViewEventOrEmptyObject = TescoPageViewEvent | EmptyObject;

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const getPostcode = (store: RootState): string => {
  const postcodeFromSessionStorage = retrieveData(TESCO_PAGE_LOAD_POSTCODE);

  if (postcodeFromSessionStorage) return postcodeFromSessionStorage;

  const postcodeFromStore = store?.customerDetails?.address?.postcode;

  if (postcodeFromStore) {
    storeData(TESCO_PAGE_LOAD_POSTCODE, postcodeFromStore);
    return postcodeFromStore;
  }

  return '';
};

const getJourneyId = (): string => {
  const journeyId = 'dd-ins-pet-00008';
  if (
    activeEnv === 'test' ||
    activeEnv === 'development' ||
    activeEnv === 'preview' ||
    activeEnv === 'maintenance'
  ) {
    return `${journeyId}-dev`;
  }
  return `${journeyId}-prod`;
};

export const trackTescoOptanonGroups = (message: unknown): void => {
  const OnetrustGroups: OptanonType = {
    OnetrustActiveGroups: 'C0001',
    event: 'OneTrustGroupsUpdated',
  };

  const lastOneTrustEvent: OptanonType = window?.tb_ddl
    ?.filter((ev) => ev.event === 'OneTrustGroupsUpdated')
    .pop() as OptanonType;

  if (message) {
    const optanonConsent = getCookie('OptanonConsent');
    const optanonConsents = decodeURIComponent(optanonConsent);
    const optanonConsentss = optanonConsents
      .split('&')
      .filter((row) => row.startsWith('groups='))
      .map((row) => row.replace('groups=', ''))
      .join('')
      .split(',')
      .filter((row) => row.endsWith(':1'))
      .map((row) => row.replace(':1', ''))
      .join(',');
    OnetrustGroups.OnetrustActiveGroups = optanonConsentss;
    if (lastOneTrustEvent?.OnetrustActiveGroups !== OnetrustGroups.OnetrustActiveGroups) {
      trackTescoEvent(OnetrustGroups);
    }
  }
};

export const trackTescoPageLoad = (store: RootState): void => {
  eventEmitter.subscribe('onetrustCookieUpdate', trackTescoOptanonGroups);

  const postcode = getPostcode(store);
  const journeyId = getJourneyId();

  const postcodeRegion = postcode?.slice(0, 2) ?? '';

  const tescoPageLoadEvent: TescoPageLoadEvent = {
    event: 'page_load',
    journey: {
      journeyinfo: {
        journey_id: journeyId,
      },
    },
    user: {
      userinfo: {
        user_postcode_region: postcodeRegion,
        user_language_selected:
          typeof window === 'undefined' ? undefined : window?.navigator?.language,
        user_device_recognised: false,
        user_logged_in: false,
      },
    },
    version: {
      versioninfo: {
        version_id: 'v1.1.0',
      },
    },
  };

  trackTescoEvent(tescoPageLoadEvent);
};

// Hook to track in two cases:
// Case 1 - when page loads if not tracked already on another page
//          The tracking status is kept on Redux - tescoPageEventTracking
// Case 2 - When user refreshes page
export const useTescoPageLoadTracking = (): void => {
  const store = useSelector((state: RootState) => state);
  const dispatch = useDispatch<Dispatch<UpdateTescoPageLoadTrackingStatusAction>>();

  const pageLoadAlreadyTracked = store?.tescoEventTracking?.pageLoadTracked;

  const sessionStoragePageLoadTracked = retrieveDataWithExpiry(
    TESCO_PAGE_LOAD_TRACKED_STORAGE_KEY
  );
  useEffect(() => {
    if (!pageLoadAlreadyTracked && !sessionStoragePageLoadTracked) {
      trackTescoPageLoad(store);
      storeDataWithExpiry(TESCO_PAGE_LOAD_TRACKED_STORAGE_KEY, 'true', 3500);
      dispatch({ type: UPDATE_TESCO_PAGE_LOAD_TRACKING_STATUS, pageLoadTracked: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoadAlreadyTracked, dispatch]);
};

const getPageUrlQuerystring = (location: Location): string | undefined => {
  const params = parse(location.search);
  if ('INTID' in params) {
    return `INTID=${params.INTID}`;
  }
  if ('CMPID' in params) {
    return `CMPID=${params.CMPID}`;
  }
  if ('REFERRERID' in params) {
    return `REFERRERID=${params.REFERRERID}`;
  }
  if ('EXTID' in params) {
    return `EXTID=${params.EXTID}`;
  }
  return undefined;
};

const getAggregatorFromDeepLinkParams = (
  deepLinkParams: DeeplinkRequest | null
): string | undefined => {
  if (
    deepLinkParams?.gocoUuid ||
    deepLinkParams?.referralRouteId === ProductId.GO_COMPARE
  )
    return 'gc';
  if (
    deepLinkParams?.msmSsid ||
    deepLinkParams?.msmUrn ||
    deepLinkParams?.referralRouteId === ProductId.MONEY_SUPERMARKET
  )
    return 'ms';
  if (
    deepLinkParams?.qn ||
    deepLinkParams?.referralRouteId === ProductId.COMPARE_THE_MARKET
  )
    return 'cm';
  return undefined;
};

const getTescoProductIdFromQuoteOptions = (
  quoteOptions: QuoteOptions
): string | undefined => {
  switch (quoteOptions.accidentAndIllnessCoverDuration) {
    case AccidentAndIllnessCoverDuration.Short_Term:
      return 'standard_3000';
    case AccidentAndIllnessCoverDuration.Until_Limit:
      return `extra_${quoteOptions.extraCoverFeeLimit}`;
    case AccidentAndIllnessCoverDuration.Ongoing:
      return `premier_${quoteOptions.premierCoverFeeLimit}`;
    default:
      return undefined;
  }
};

const getPreviousPageName = (
  sessionStoragePageViewObject: TescoPageViewEventOrEmptyObject
): string | undefined => {
  if (sessionStoragePageViewObject?.page?.pageinfo?.page_name) {
    return sessionStoragePageViewObject?.page?.pageinfo?.page_name;
  }
  return undefined;
};

const getJourneyType = (
  store: RootState,
  sessionStoragePageViewObject: TescoPageViewEventOrEmptyObject
): string => {
  // We first check the sessionStorage page_view object. If the value exists there we return it.
  // This should work even if the user refreshes or we are no longer retrieving quote from backend
  // as is the case in the Confirmation page.
  if (
    sessionStoragePageViewObject?.application?.applicationinfo?.application_journey_type
  ) {
    return sessionStoragePageViewObject?.application?.applicationinfo
      ?.application_journey_type;
  }

  const deepLinkParams = getDeepLinkParams(document.location);
  const aggFromReferrer = getAggregatorFromReferrer(document?.referrer);
  const aggFromProductId = getAggregatorFromProductId(store?.quote?.productId);
  const aggFromDeepLinkParams = getAggregatorFromDeepLinkParams(deepLinkParams);
  const aggregatorCode = aggFromReferrer ?? aggFromDeepLinkParams ?? aggFromProductId;

  const onRetrieveQuotePage = window?.location?.pathname.includes('retrieve-quote');
  const onAboutYourPetPage = window?.location?.pathname.includes(
    'about-you-and-your-pet'
  );
  const haveBeenOnRetrieveQuotePage =
    retrieveData(TESCO_PAGE_VIEW_IS_RETRIEVED_QUOTE) === 'true';

  if (onRetrieveQuotePage && !haveBeenOnRetrieveQuotePage) {
    storeData(TESCO_PAGE_VIEW_IS_RETRIEVED_QUOTE, 'true');
  }

  if (onRetrieveQuotePage || haveBeenOnRetrieveQuotePage || aggFromDeepLinkParams) {
    return aggregatorCode ? 'rag' : 'rdr';
  }

  if (onAboutYourPetPage) {
    return aggregatorCode ? 'nag' : 'ndr';
  }

  return '';
};

const getUrlSlug = (url: string): string => {
  const urlPartsArray = url.split('/');
  const lastNonEmptyStringInUrlPartsArray = urlPartsArray.filter((part) => part).pop();
  return lastNonEmptyStringInUrlPartsArray ?? '';
};

const getTagGroup = (): string | undefined => {
  const pathname = window?.location?.pathname;
  if (typeof pathname === undefined) return undefined;

  switch (pathname) {
    case quoteAndBuyRoutes.aboutYouAndYourPet:
      return 'st';
    case quoteAndBuyRoutes.additionalQuestions:
      return 'st';
    case quoteAndBuyRoutes.loadingQuote:
      return 'st';
    case quoteAndBuyRoutes.quoteSummary:
      return 'qt';
    case quoteAndBuyRoutes.checkYourDetails:
      return 'qt';
    case quoteAndBuyRoutes.payment:
      return 'py';
    case quoteAndBuyRoutes.paymentRedirect:
      return 'py';
    case quoteAndBuyRoutes.paymentDone:
      return 'py';
    case quoteAndBuyRoutes.paymentBack:
      return 'py';
    case quoteAndBuyRoutes.paymentError:
      return 'er';
    case quoteAndBuyRoutes.confirmation:
      return 'cp';
    case quoteAndBuyRoutes.retrieveQuote:
      return 'rt';
    case quoteAndBuyRoutes.retrieveQuoteReference:
      return 'rt';
    case quoteAndBuyRoutes.ineligibleQuote:
      return 'dc';
    case quoteAndBuyRoutes.sessionExpired:
      return 'tm';
    case quoteAndBuyRoutes.confirmationSessionExpired:
      return 'tm';
    case quoteAndBuyRoutes.confirmCoverStartDate:
      return 'st';
    default:
      return undefined;
  }
};

const getExcessAmountAsNumber = (pctExcess: VoluntaryExcessAmount): number => {
  switch (pctExcess) {
    case VoluntaryExcessAmount.EXCESS_60:
      return 60;
    case VoluntaryExcessAmount.EXCESS_120:
      return 120;
    case VoluntaryExcessAmount.EXCESS_200:
      return 200;
    default:
      return 0;
  }
};

const getExcessPercentageAsNumber = (pctExcess: VoluntaryExcessPercentage): number => {
  switch (pctExcess) {
    case VoluntaryExcessPercentage.EXCESS_0_PERCENT:
      return 0;
    case VoluntaryExcessPercentage.EXCESS_10_PERCENT:
      return 10;
    case VoluntaryExcessPercentage.EXCESS_20_PERCENT:
      return 20;
    default:
      return 0;
  }
};

const getTescoPetExcessesFromQuote = (quote: CurrentQuote): TescoPetExcesses[] => {
  const tescoPetExcesses: TescoPetExcesses[] = [];

  quote?.petInfos?.forEach((pet) => {
    const petExcesses = {
      agreement_excess_mandatory: getExcessAmountAsNumber(
        pet.voluntaryExcessAmount
      ).toFixed(2),
      agreement_excess_voluntary: getExcessPercentageAsNumber(
        pet.voluntaryExcessPercentage
      ).toFixed(2),
    };
    tescoPetExcesses.push(petExcesses);
  });

  return tescoPetExcesses;
};

// This should be the host name from page_referrer_url.
// just the name excluding the "www." & ".com".
// So if the previous page was "www.tescobank.com/apply" the host name value would be "tescobank"
const getPageReferrerDomain = (): string => {
  const referrerUrl = document?.referrer ?? '';
  const domainName = referrerUrl.split('/')[2] ?? '';
  const domainParts = domainName.split('.');
  return domainParts[domainParts.length - 2] ?? '';
};

const deepCloneExcessesArray = (
  excessesArray: TescoPetExcesses[] | undefined
): TescoPetExcesses[] => {
  if (typeof excessesArray === 'undefined') {
    return [];
  }
  const clonedExcessesArray = excessesArray.map((excess) => ({ ...excess }));
  return clonedExcessesArray;
};

const getApplicationId = (
  sessionStoragePageViewObject: TescoPageViewEventOrEmptyObject
): string => {
  if (sessionStoragePageViewObject?.application?.applicationinfo?.application_id) {
    return sessionStoragePageViewObject.application.applicationinfo.application_id;
  }

  const getVisitorIdSubStr = (adobeOrganizationId: string): string => {
    // get Adobe Visitor ID of fall back to uuidv4
    let lastSevenDigitsOfId: string | undefined;
    try {
      lastSevenDigitsOfId = window?.Visitor?.getInstance(adobeOrganizationId)
        .getMarketingCloudVisitorID()
        .slice(-7);

      if (typeof lastSevenDigitsOfId === 'undefined') throw new Error();
    } catch {
      lastSevenDigitsOfId = uuidv4().slice(-7);
    }

    return lastSevenDigitsOfId;
  };

  const visitorIDSubStr = getVisitorIdSubStr(ADOBE_ORG_ID);
  const timestamp = new Date().getTime();
  return `${timestamp}${visitorIDSubStr}`;
};

const isApplicationPrepopulated = (appJourneyType: string): boolean | undefined => {
  if (['rag', 'nag'].includes(appJourneyType)) {
    return true;
  }
  if (['rdr', 'ndr'].includes(appJourneyType)) {
    return false;
  }
  return undefined;
};

const getNumberOfPets = (
  store: RootState,
  sessionStoragePageViewObject: TescoPageViewEventOrEmptyObject
): string => {
  return (
    sessionStoragePageViewObject?.application?.applicationattributes?.attributedetail
      ?.number_of_pets ??
    store?.petsDetails?.length?.toString() ??
    ''
  );
};

export const trackTescoPageView = (
  eventLevel: TescoPageViewEventLevel,
  store: RootState,
  quote: CurrentQuote,
  location: Location
): void => {
  //
  // In some pages (such as the Confirmation page) we do not retrieve quote from backend.
  // To overcome this issue we use a page_view object previously stored in session storage
  // (pageViewObjectFromSessionStorage) to populate the event object to track.
  //
  // This is also necessary in some cases when the user refreshes page (F5) because
  // Tesco event tracking may happen before quote is fetched
  //
  let pageViewObjectFromSessionStorage: TescoPageViewEvent | EmptyObject;

  // JSON.parse can throw a runtime error if the string is not in valid JSON format.
  try {
    const pageViewStringFromSessionStorage = retrieveData(TESCO_PAGE_VIEW_OBJECT) || '';
    pageViewObjectFromSessionStorage = JSON.parse(pageViewStringFromSessionStorage);
  } catch (e) {
    pageViewObjectFromSessionStorage = {};
  }
  const urlSlug = getUrlSlug(window?.location?.pathname);
  const currentPageName = `tescobank:pet:quoteprocess:${urlSlug}`;

  const decodedTescoBankEnsightenCookieValue = decodeURIComponent(
    getCookie('ens_gpv_pn')
  );
  setCookie('ens_gpv_pn', encodeURIComponent(currentPageName));

  const deepLinkParams = getDeepLinkParams(location);
  const aggFromReferrer = getAggregatorFromReferrer(document?.referrer);
  const aggFromDeepLinkParams = getAggregatorFromDeepLinkParams(deepLinkParams);
  const aggFromProductId = getAggregatorFromProductId(quote.productId);
  const aggregatorCode = aggFromReferrer ?? aggFromDeepLinkParams ?? aggFromProductId;
  const previousPageName = getPreviousPageName(pageViewObjectFromSessionStorage);
  const tagGroup = getTagGroup();
  const pageReferrerDomain = getPageReferrerDomain();

  const applicationId = getApplicationId(pageViewObjectFromSessionStorage);
  const applicationCaseId = applicationId ? applicationId.slice(-10) : undefined;
  const applicationJourneyType = getJourneyType(store, pageViewObjectFromSessionStorage);
  const applicationPrepopulated = isApplicationPrepopulated(applicationJourneyType);

  const numberOfPets = getNumberOfPets(store, pageViewObjectFromSessionStorage);

  const tescoPageViewEvent: TescoPageViewEvent = {
    event: 'page_view',
    page: {
      pageinfo: {
        page_type: 'product application',
        page_name: currentPageName,
        page_url: window.location.href,
        page_url_domain: window?.location?.origin,
        page_url_path: window?.location?.pathname,
        page_url_querystring: getPageUrlQuerystring(location),
        page_pcw_landing_page: !!aggregatorCode,
        page_previous_name: previousPageName,
        page_tag_group: tagGroup,
      },
      attributes: {
        page_referrer_url: document?.referrer,
        page_referrer_domain: pageReferrerDomain,
        page_referrer_page_name: decodedTescoBankEnsightenCookieValue,
        page_referrer_PCW_code: aggregatorCode,
        page_hit_datestamp: new Date().toLocaleDateString(),
        page_hit_timestamp: new Date().toLocaleTimeString(),
      },
    },
    application: {
      applicationinfo: {
        application_aperio_id: undefined,
        application_id: applicationId,
        application_case_id: applicationCaseId,
        application_digital_id: `00008a${applicationId}`,
        application_prepopulated: applicationPrepopulated,
        application_journey_type: applicationJourneyType,
      },
      applicationattributes: {
        attributedetail: {
          number_of_pets: numberOfPets,
          pet_type: [],
        },
      },
    },
  };

  if (eventLevel === TescoPageViewEventLevel.extended) {
    const productId =
      getTescoProductIdFromQuoteOptions(store?.quoteSummaryOptions) ??
      pageViewObjectFromSessionStorage?.basket?.basketinfo?.item?.[0]?.productinfo
        ?.product_id;

    const policyStartDateString = store?.policyDetails?.coverStartDate;

    const reversedStartCoverDateString = policyStartDateString
      ? getDateOnlyIsoString(policyStartDateString).split('-').reverse().join('/')
      : undefined;

    const parsedStartDate = parseDate(policyStartDateString);

    const policyRenewalDateString = isDateValid(parsedStartDate)
      ? getDateOnlyIsoString(addYearsToDate(parsedStartDate, 1).toISOString())
      : undefined;

    const reversedRenewalDateString = policyRenewalDateString
      ? policyRenewalDateString.split('-').reverse().join('/')
      : undefined;

    const policyEndDateString = policyRenewalDateString
      ? getDateOnlyIsoString(
          addDaysToDate(parseDate(policyRenewalDateString), -1).toISOString()
        )
      : undefined;

    const reversedEndDateString = policyEndDateString
      ? policyEndDateString.split('-').reverse().join('/')
      : undefined;

    const agreementOutcome = location?.pathname?.includes('ineligible-quote') ? '4' : '1';

    const tescoPetExcessesFromQuote = getTescoPetExcessesFromQuote(quote);

    const tescoPetExcesses =
      tescoPetExcessesFromQuote.length !== 0
        ? tescoPetExcessesFromQuote
        : deepCloneExcessesArray(
            pageViewObjectFromSessionStorage?.basket?.basketinfo?.item?.[0]?.agreementinfo
              ?.excesses
          );

    const monthlyPaymentDate = store?.tescoEventTracking?.monthlyPaymentDate
      ? store?.tescoEventTracking?.monthlyPaymentDate
      : undefined;

    const paymentFrequency = quote?.quoteOptions?.isAnnualPayment ? '1' : '3';
    const paymentDurationMonths = quote?.quoteOptions?.isAnnualPayment ? '0' : '12';
    const totalAnnualPayment = quote?.price?.annualPrice?.total
      ? quote?.price?.annualPrice?.total.toString()
      : pageViewObjectFromSessionStorage?.basket?.basketinfo?.basket_payment_amount;

    const agreementDecisionId =
      quote?.policyInfo?.quoteNumber ??
      pageViewObjectFromSessionStorage?.basket?.basketinfo?.item?.[0]?.agreementinfo
        ?.agreement_decision_id;

    const agreementSaleId = agreementDecisionId;

    const paymentRecurringAmount = quote?.quoteOptions?.isAnnualPayment
      ? ''
      : quote?.price?.monthlyPrice?.total.toString() ??
        pageViewObjectFromSessionStorage?.basket?.basketinfo?.item?.[0]?.paymentinfo
          ?.payment_recurring_amount;

    tescoPageViewEvent.basket = {
      basketinfo: {
        item: [
          {
            productinfo: {
              product_id: productId,
              product_position: undefined,
              product_tier: getProductTier(),
              product_auto_applied: false,
              product_quantity: '1',
              product_price: totalAnnualPayment,
            },
            agreementinfo: {
              agreement_decision_id: agreementDecisionId ?? undefined,
              agreement_sale_id: agreementSaleId,
              agreement_duration_months: '12',
              excesses: tescoPetExcesses,
              agreement_num_persons: store?.jointPolicyholderDetails
                .includeJointPolicyholder
                ? '2'
                : '1',
              agreement_start_date: reversedStartCoverDateString,
              agreement_end_date: reversedEndDateString,
              agreement_renewal_date: reversedRenewalDateString,
              agreement_outcome: agreementOutcome,
            },
            promotions: [
              {
                promotions_id: undefined,
                promotions_discount_amount: undefined,
              },
            ],
            paymentinfo: {
              payment_paperless_bill: false,
              payment_day_of_the_month: monthlyPaymentDate,
              payment_frequency: paymentFrequency,
              payment_duration_months: paymentDurationMonths,
              payment_deposit_amount: '0',
              payment_recurring_amount: paymentRecurringAmount,
              payment_amount: totalAnnualPayment,
            },
          },
        ],
        basket_payment_amount: totalAnnualPayment,
      },
    };
  }

  storeData(TESCO_PAGE_VIEW_OBJECT, JSON.stringify(tescoPageViewEvent));
  trackTescoEvent(tescoPageViewEvent);
};

export const trackTescoErrorPageView = (
  eventLevel: TescoPageViewEventLevel,
  store: RootState,
  quote: CurrentQuote,
  location: Location
): void => {
  const tagGroup = getTagGroup();
  const tescoErrorPageViewEvent: TescoPageViewEvent = {
    event: 'error_page_view',
    page: {
      pageinfo: {
        page_type: 'product application',
        page_name: '',
        page_url: window.location.href,
        page_url_domain: window?.location?.origin,
        page_url_path: window?.location?.pathname,
        page_url_querystring: getPageUrlQuerystring(location),
        page_pcw_landing_page: false,
        page_previous_name: '',
        page_tag_group: tagGroup,
      },
      attributes: {
        page_referrer_url: document?.referrer,
        page_referrer_domain: undefined,
        page_referrer_page_name: undefined,
        page_referrer_PCW_code: undefined,
        page_hit_datestamp: new Date().toLocaleDateString(),
        page_hit_timestamp: new Date().toLocaleTimeString(),
      },
    },
  };

  trackTescoEvent(tescoErrorPageViewEvent);
};

export const useTescoPageViewTracking = (
  eventLevel = TescoPageViewEventLevel.basic
): void => {
  const location = useLocation();
  const [tracked, setTracked] = useState(false);
  const store = useSelector((state: RootState) => state);
  const quote = useCurrentQuote();

  useEffect(() => {
    if (!tracked) {
      if (
        eventLevel === TescoPageViewEventLevel.basic ||
        eventLevel === TescoPageViewEventLevel.extended
      ) {
        trackTescoPageView(eventLevel, store, quote, location);
      }
      if (eventLevel === TescoPageViewEventLevel.error) {
        trackTescoErrorPageView(eventLevel, store, quote, location);
      }
      setTracked(true);
    }
  }, [tracked, eventLevel, store, quote, location]);
};

export const useResetTescoPageLoadTrackingOnRefresh = (): void => {
  const dispatch = useDispatch<Dispatch<UpdateTescoPageLoadTrackingStatusAction>>();

  useEffect(() => {
    const loadCallback = (): void => {
      dispatch({ type: UPDATE_TESCO_PAGE_LOAD_TRACKING_STATUS, pageLoadTracked: false });
    };

    window.addEventListener('load', loadCallback);
    return () => window.removeEventListener('load', loadCallback);
  }, [dispatch]);
};
